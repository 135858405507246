<script lang="ts" setup>
import { useMainStore } from "~/stores/main";

// -----------------------
// props & emits
// -----------------------
const props = withDefaults(
  defineProps<{
    parentId: number;
    maxLevels?: number;
    sticky?: boolean;
  }>(),
  {
    maxLevels: 1,
    sticky: true,
  },
);

// -----------------------
// composables
// -----------------------
const route = useRoute();
const mainStore = useMainStore();

// -----------------------
// reactive properties
// -----------------------
const items = ref<Array<Record<string, any>> | null>(null);
const headerHeight = ref<number>(0);

// -----------------------
// computed properties
// -----------------------
const firstLevel = computed(() => {
  if (!items.value) {
    return null;
  }

  return items.value
    .filter(({ parent_id }) => parent_id === props.parentId)
    .map(({ id, url, name }) => ({ id, url, name }));
});

const firstLevelSelectedId = computed(() => {
  if (!firstLevel.value) {
    return null;
  }

  let selected = firstLevel.value.find(({ url }) => url === route.path);

  if (selected) {
    return selected.id;
  }

  if (!secondLevel.value) {
    return null;
  }

  selected = secondLevel.value.find(({ url }) => url === route.path);

  if (selected) {
    return selected.parent_id;
  } else {
    return null;
  }
});

const secondLevel = computed(() => {
  if (props.maxLevels === 1) {
    return null;
  }

  if (!items.value) {
    return null;
  }

  const selected = items.value.find(({ url }) => url === route.path);

  if (!selected) {
    return null;
  }

  const firstLevelItemSelected = selected.parent_id === props.parentId;
  const filterParameter = firstLevelItemSelected
    ? selected.id
    : selected.parent_id;

  return items.value
    .filter(({ parent_id }) => parent_id === filterParameter)
    .map(({ id, parent_id, url, name }) => ({ id, parent_id, url, name }));
});

const secondLevelSelectedId = computed(() => {
  if (!secondLevel.value) {
    return null;
  }

  return secondLevel.value.find(({ url }) => url === route.path)?.id ?? null;
});

// When the menu is sticky (sticky property set to true; default state)
// the (CMS page) content needs to move down with the size of the menu
// due to the fixed/absolute positioning of the container
const containerStyle = computed(() => {
  if (secondLevel.value) {
    return { "margin-top": "160px" };
  }

  return { "margin-top": "80px" };
});

const floatingContainerStyle = computed(() => {
  if (!props.sticky) {
    return {};
  }

  if (mainStore.headerPosition === "fixed") {
    return { top: `${headerHeight.value}px` };
  }

  return { top: `${mainStore.headerTop + headerHeight.value}px` };
});

// -----------------------
// helper methods
// -----------------------
const fetchItems = () => {
  items.value = null;

  if (props.maxLevels === 1) {
    $fetch(`/api/meilisearch/cmspage`, {
      params: { parentId: props.parentId },
    }).then((menuItems) => {
      items.value = menuItems;
    });
  } else {
    $fetch(`/api/meilisearch/cmspage`, {
      params: { ancestorId: props.parentId },
    }).then((menuItems) => {
      items.value = menuItems;
    });
  }
};

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  fetchItems();

  if (props.sticky) {
    const selector = mainStore.isMobile
      ? "#mobileHeaderElement"
      : "#headerElement";

    const headerHeightFn = setInterval(() => {
      const headerElement = document.body.querySelector(selector);

      if (headerElement instanceof Element) {
        headerHeight.value = headerElement.getBoundingClientRect().height;
        clearInterval(headerHeightFn);
      }
    }, 100);
  }
});
watch(() => props.maxLevels, fetchItems);
</script>

<template>
  <template v-if="!sticky">
    <div class="min-h-[80px]">
      <!-- Level 1 -->
      <PageMenuLevel
        v-if="firstLevel"
        :level="1"
        :items="firstLevel"
        :selected-id="firstLevelSelectedId"
      ></PageMenuLevel>

      <!-- Level 2 -->
      <PageMenuLevel
        v-if="secondLevel"
        :level="2"
        :items="secondLevel"
        :selected-id="secondLevelSelectedId"
      ></PageMenuLevel>
    </div>
  </template>

  <template v-else>
    <div :style="containerStyle">
      <div
        class="fixed z-10 left-0 right-0"
        :class="{ [mainStore.headerPosition]: true }"
        :style="floatingContainerStyle"
      >
        <!-- Level 1 -->
        <PageMenuLevel
          v-if="firstLevel"
          :level="1"
          :items="firstLevel"
          :selected-id="firstLevelSelectedId"
        ></PageMenuLevel>

        <!-- Level 2 -->
        <PageMenuLevel
          v-if="secondLevel"
          :level="2"
          :items="secondLevel"
          :selected-id="secondLevelSelectedId"
        ></PageMenuLevel>
      </div>
    </div>
  </template>
</template>
