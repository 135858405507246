<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
defineProps<{
  level: number;
  items: Array<Record<string, any>>;
  selectedId: number | null;
}>();

// -----------------------
// reactive properties
// -----------------------
const requiredWidth = ref<number>(0);
const isScrollable = ref<boolean>(false);
const scrollPosition = ref<number>(0);
const scrollLeftFn = ref<number | null>(null);
const scrollRightFn = ref<number | null>(null);
const linksContainer = ref();
const linksContainerWidth = ref<number>(0);
const dragStartPosition = ref<number>(0);
const dragDirection = ref<string>();
const dragStarted = ref<number>(0);
const dragEnding = ref<boolean>(false);
const forbidContextMenu = ref<boolean>(false);

// -----------------------
// computed properties
// -----------------------
const maxScrollPosition = computed(() => {
  return requiredWidth.value - linksContainerWidth.value;
});

const canScrollLeft = computed(() => {
  return scrollPosition.value > 0;
});

const canScrollRight = computed(() => {
  return scrollPosition.value < maxScrollPosition.value;
});

const scrollStyle = computed(() => {
  if (scrollPosition.value === 0) {
    return {};
  }

  return {
    "margin-left": `-${scrollPosition.value}px`,
  };
});

// -----------------------
// helper methods
// -----------------------
const updateIsScrollable = () => {
  isScrollable.value = document.body.clientWidth <= requiredWidth.value + 100;

  if (!isScrollable.value) {
    scrollPosition.value = 0;
  }
};

const updateLinksContainerWidth = () => {
  linksContainerWidth.value = document.body.clientWidth - 84;
};

const startScrollLeft = () => {
  if (scrollLeftFn.value || !canScrollLeft.value) {
    return;
  }

  forbidContextMenu.value = true;
  scrollLeftFn.value = setInterval(() => {
    if (scrollPosition.value - 3 > 0) {
      scrollPosition.value -= 3;
    } else {
      scrollPosition.value = 0;
    }

    if (!canScrollLeft.value) {
      endScrollLeft(false);
    }
  }, 20);
};

const endScrollLeft = (allowContextMenu: boolean = false) => {
  clearInterval(scrollLeftFn.value);
  scrollLeftFn.value = null;

  if (allowContextMenu) {
    forbidContextMenu.value = false;
  }
};

const startScrollRight = () => {
  if (scrollRightFn.value || !canScrollRight.value) {
    return;
  }

  forbidContextMenu.value = true;
  scrollRightFn.value = setInterval(() => {
    if (scrollPosition.value + 3 <= maxScrollPosition.value) {
      scrollPosition.value += 3;
    } else {
      scrollPosition.value = maxScrollPosition.value;
    }

    if (!canScrollRight.value) {
      endScrollRight(false);
    }
  }, 20);
};

const endScrollRight = (allowContextMenu: boolean = true) => {
  clearInterval(scrollRightFn.value);
  scrollRightFn.value = null;

  if (allowContextMenu) {
    forbidContextMenu.value = false;
  }
};

const drag = (event: TouchEvent) => {
  if (!("touches" in event)) {
    return;
  }

  if (dragStarted.value === 0) {
    dragStarted.value = Date.now();
  }

  forbidContextMenu.value = true;

  if (dragStartPosition.value === 0) {
    dragStartPosition.value = event.touches[0].clientX;
  }

  const distance = dragStartPosition.value - event.touches[0].clientX;
  dragDirection.value = distance > 0 ? "left" : "right";

  if (
    (dragDirection.value === "left" && !canScrollRight.value) ||
    (dragDirection.value === "right" && !canScrollLeft.value)
  ) {
    return;
  }

  if (dragDirection.value === "right" && scrollPosition.value + distance < 0) {
    scrollPosition.value = 0;
  } else if (
    dragDirection.value === "left" &&
    scrollPosition.value + distance > maxScrollPosition.value
  ) {
    scrollPosition.value = maxScrollPosition.value;
  } else {
    scrollPosition.value += distance;
  }

  dragStartPosition.value = event.touches[0].clientX;
};

const dragEnd = () => {
  const dragDuration = Date.now() - dragStarted.value;

  if (dragDuration < 150) {
    dragEnding.value = true;

    if (dragDirection.value === "left") {
      if (scrollPosition.value + 100 <= maxScrollPosition.value) {
        scrollPosition.value += 100;
      } else {
        scrollPosition.value = maxScrollPosition.value;
      }
    } else if (scrollPosition.value - 100 > 0) {
      scrollPosition.value -= 100;
    } else {
      scrollPosition.value = 0;
    }

    setTimeout(() => {
      dragEnding.value = false;
    }, 510);
  }

  dragStarted.value = 0;
  dragStartPosition.value = 0;
  forbidContextMenu.value = false;
};

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  requiredWidth.value = linksContainer.value.getBoundingClientRect().width;

  updateIsScrollable();
  updateLinksContainerWidth();
});

window.addEventListener("resize", () => {
  updateIsScrollable();
  updateLinksContainerWidth();
});

window.addEventListener("contextmenu", (event) => {
  if (forbidContextMenu.value) {
    event.preventDefault();
    event.stopPropagation();

    return false;
  }
});
</script>

<template>
  <div
    v-touch:drag="drag"
    v-touch:release="dragEnd"
    v-touch-options="{ dragFrequency: 10 }"
    class="flex bg-brand-surface h-[80px] items-center"
    :class="{
      'px-0': !isScrollable,
      'px-[8px]': isScrollable,
      shadow: level > 1,
    }"
  >
    <button
      v-if="isScrollable"
      v-touch:press="startScrollLeft"
      v-touch:release="endScrollLeft"
      class="grow-0 shrink-0 mr-[14px]"
      :disabled="!canScrollLeft"
      :class="{ 'opacity-30': !canScrollLeft }"
      @mousedown="startScrollLeft"
      @mouseup="endScrollLeft"
    >
      <i class="m-icon-arrow-left text-20"></i>
    </button>
    <div
      ref="linksContainer"
      class="flex links-container"
      :class="{
        'justify-start': isScrollable,
        'justify-center': !isScrollable,
        'grow shrink overflow-hidden': requiredWidth !== 0,
        'shrink-0': requiredWidth === 0,
      }"
    >
      <div
        :style="scrollStyle"
        :class="{ 'transition-all duration-500 ease-out': dragEnding }"
      ></div>
      <NuxtLink
        v-for="(item, index) in items"
        :key="item.id"
        :to="item.url"
        class="!text-brand-headline"
        :class="{
          'font-bold': item.id === selectedId,
          'grow-0 shrink': !isScrollable,
          'grow shrink-0': isScrollable,
          'mr-[40px]': index < items.length - 1,
        }"
        >{{ item.name }}</NuxtLink
      >
    </div>
    <button
      v-if="isScrollable"
      v-touch:press="startScrollRight"
      v-touch:release="endScrollRight"
      class="grow-0 shrink-0 ml-[14px]"
      :disabled="!canScrollRight"
      :class="{ 'opacity-30': !canScrollRight }"
      @mousedown="startScrollRight"
      @mouseup="endScrollRight"
    >
      <i class="m-icon-arrow-right text-20"></i>
    </button>
  </div>
</template>

<style scoped>
.shadow {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.12);
}
</style>
